import React, { useRef, useState } from 'react';
import { Box, TextInput, Text } from 'grommet';

interface DateInputProps {
    initialDate: string;
    onDateChange: (date: string) => void;
}

function checkValidity(n: number, a: number): [boolean, boolean] {
    if (n <= 0 || a <= 0) {
        return [true, false];
    }

    const valid = a <= n;

    if (!valid) {
        return [valid, false];
    }

    let lastNumber = true;
    const aStr = a.toString();
    const nStr = n.toString();

    if (aStr.length === nStr.length) {
        for (let i = 0; i < 10; i++) {
            if (parseInt(aStr + i.toString()) <= n) {
                lastNumber = false;
                break;
            }
        }
    } else {
        lastNumber = a * 10 > n;
    }

    return [valid, lastNumber];
}

const DateInput: React.FC<DateInputProps> = ({ initialDate, onDateChange }) => {
    const [birthdayInputMM, setBirthdayInputMM] = useState(initialDate.split('/')[0]);
    const [birthdayInputDD, setBirthdayInputDD] = useState(initialDate.split('/')[1]);
    const [birthdayInputYYYY, setBirthdayInputYYYY] = useState(initialDate.split('/')[2]);

    const dayInputRef = useRef<HTMLInputElement | null>(null);
    const yearInputRef = useRef<HTMLInputElement | null>(null);

    const setDayFocus = () => {
        if (dayInputRef.current !== null) {
            dayInputRef.current.focus();
        }
    };

    const setYearFocus = () => {
        if (yearInputRef.current !== null) {
            yearInputRef.current.focus();
        }
    };

    const handleMonthChange = (value: string) => {
        const [isValid, isLastNumber] = checkValidity(12, Number(value));
        if (isValid) {
            setBirthdayInputMM(value);
            if (isLastNumber) {
                setDayFocus();
            }
            if (value){
                onDateChange(`${value}-${birthdayInputDD}-${birthdayInputYYYY}`);
            }
        }

    };

    const handleDayChange = (value: string) => {
        const daysInMonth = new Date(Number(birthdayInputYYYY) || 1900, Number(birthdayInputMM), 0).getDate();
        const [isValid, isLastNumber] = checkValidity(daysInMonth, Number(value));
        if (isValid) {
            setBirthdayInputDD(value);
            if (isLastNumber) {
                setYearFocus();
            }
            if (value){
                onDateChange(`${birthdayInputMM}-${value}-${birthdayInputYYYY}`);
            }
        }
    };

    const handleYearChange = (value: string) => {
        setBirthdayInputYYYY(value);
        onDateChange(`${birthdayInputMM}-${birthdayInputDD}-${value}`);
    };

    return (
        <Box direction="row" gap="small" wrap={false} style={{flex: 'no-shrink'}}>
            <Box width="100px">
                <TextInput
                    textAlign="center"
                    value={birthdayInputMM}
                    onChange={(e) => handleMonthChange(e.target.value)}
                    maxLength={2}
                />
            </Box>
            <Text size="xxlarge">-</Text>
            <Box width="100px">
                <TextInput
                    id="dayInput"
                    ref={dayInputRef}
                    textAlign="center"
                    value={birthdayInputDD}
                    onChange={(e) => handleDayChange(e.target.value)}
                    maxLength={2}
                />
            </Box>
            <Text size="xxlarge">-</Text>
            <Box width="125px">
                <TextInput
                    id="yearInput"
                    ref={yearInputRef}
                    textAlign="center"
                    value={birthdayInputYYYY}
                    onChange={(e) => handleYearChange(e.target.value)}
                    maxLength={4}
                />
            </Box>
        </Box>
    );
};

export default DateInput;
