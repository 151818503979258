import React from 'react';
import { Box, Button, Layer, Text, Grid } from 'grommet';

interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

interface MergeConfirmationLayerProps {
    correctUser: User;
    incorrectUser: User;
    onConfirm: () => void;
    onCancel: () => void;
}

const MergeConfirmationLayer: React.FC<MergeConfirmationLayerProps> = ({ correctUser, incorrectUser, onConfirm, onCancel }) => (
    <Layer onEsc={onCancel}>
        <Box pad="medium" gap="small" width="medium">
            <Text size={"small"}>Are you sure you want to merge the incorrect user's events into the correct user?
                This operation will delete the incorrect user and can not be undone.</Text>
            <Grid
                rows={['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']}
                columns={['1/2', '1/2']}
                gap="small"
                areas={[
                    { name: 'firstUserLabel', start: [0, 0], end: [1, 0] },
                    { name: 'firstUserName', start: [0, 1], end: [1, 1] },
                    { name: 'firstUserBirthday', start: [0, 2], end: [1, 2] },
                    { name: 'firstUserGender', start: [0, 3], end: [1, 3] },
                    { name: 'secondUserLabel', start: [0, 4], end: [1, 4] },
                    { name: 'secondUserName', start: [0, 5], end: [1, 5] },
                    { name: 'secondUserBirthday', start: [0, 6], end: [1, 6] },
                    { name: 'secondUserGender', start: [0, 7], end: [1, 7] },
                ]}
            >
                <Box gridArea="firstUserLabel">
                    <Text weight="bold">Correct User:</Text>
                </Box>
                <Box gridArea="firstUserName">
                    <Text>Name: {correctUser.name}</Text>
                </Box>
                <Box gridArea="firstUserBirthday">
                    <Text>Birthday: {correctUser.birthday}</Text>
                </Box>
                <Box gridArea="firstUserGender">
                    <Text>Gender: {correctUser.gender}</Text>
                </Box>

                <Box gridArea="secondUserLabel">
                    <Text weight="bold">Incorrect User:</Text>
                </Box>
                <Box gridArea="secondUserName">
                    <Text>Name: {incorrectUser.name}</Text>
                </Box>
                <Box gridArea="secondUserBirthday">
                    <Text>Birthday: {incorrectUser.birthday}</Text>
                </Box>
                <Box gridArea="secondUserGender">
                    <Text>Gender: {incorrectUser.gender}</Text>
                </Box>
            </Grid>
            <Box direction="row" gap="medium" justify="between" margin={{top: "medium"}}>
                <Button primary label="Confirm" onClick={onConfirm} />
                <Button label="Cancel" onClick={onCancel} />
            </Box>
        </Box>
    </Layer>
);

export default MergeConfirmationLayer;
