import {Auth} from "aws-amplify";
import {UserUpdateOperations} from "../types/UserUpdateOperations";

interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

interface PatchGuestParams {
    operation: UserUpdateOperations;
    userToDelete: string;
    userToKeep: string;
    updateJson: string;
}

function getIdToken() {
    return Auth.currentSession().then(session => session.getIdToken().getJwtToken());
}

export const getUsers = async (birthday: Date | undefined, name: string ): Promise<User[]> => {

    let birthdayUnix = ""
    if (birthday !== undefined){
        birthdayUnix = Math.floor(birthday.getTime() / 1000).toString();
    }

    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/guest?name=${encodeURIComponent(name)}&birthday=${birthdayUnix}`;

    // Fetch data from the API
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await getIdToken()}`,
        },
    });


    if (!response.ok) {
        return [];
    }

    // Parse the response to JSON
    const data = await response.json();

    if(data === null) {
        return [];
    }

    const users: User[] = [];
    // Assuming the response is an array of users, map it to your users array
    for (let user of data) {
        users.push({
            id: user.GuestID,
            name: user.UserName,
            birthday: new Date(user.Birthday * 1000).toLocaleDateString("en-US"),
            gender: user.Gender,
        });


    }

    return users;
}

export const patchGuest = async (params: PatchGuestParams) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify({
            UserToDelete: params.userToDelete,
            UserToKeep: params.userToKeep,
            Operation: params.operation,
            UpdateJson: params.updateJson,
        }),
    };
    await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/guestupdate`, requestOptions);
};