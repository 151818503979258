import React, { useState } from 'react';
import { Layer, Tabs, Tab, Box, Button } from 'grommet';
import { Close } from 'grommet-icons';
import UserInfoTab from './UserInfoTab';
import UserMergeTab from './UserMergeTab';

interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

interface UserTileProps {
    user: User;
    userToDelete: User | null;
    onClose: () => void;
    onSelectUserForMerge: () => void;
}

const UserTile: React.FC<UserTileProps> = ({ user, userToDelete, onClose, onSelectUserForMerge }) => {
    const [editedUser, setEditedUser] = useState({ ...user });
    const [selectedTab, setSelectedTab] = useState(userToDelete ? 1 : 0);

    const handleUserChange = (updatedUser: User) => {
        setEditedUser(updatedUser);
    };

    const handleMerge = () => {
        onClose();
    };

    const handleOnSelectUserForMerge = () => {
        onSelectUserForMerge();
    }

    return (
        <Layer background="light-1">
            <Box pad="medium">
                <Box direction="row" justify="end" align="center" fill="horizontal">
                    <Button onClick={onClose} icon={<Close />} plain />
                </Box>
                <Tabs
                    alignSelf="center"
                    activeIndex={selectedTab}
                    onActive={(tabNumber: number) => {setSelectedTab(tabNumber)}}
                >
                    <Tab title="Info">
                        <UserInfoTab
                            user={user}
                            editedUser={editedUser}
                            onUserChange={handleUserChange}
                            onClose={onClose}
                        />
                    </Tab>
                    <Tab title="Merge">
                        <UserMergeTab
                            userToMerge={user}
                            userToDelete={userToDelete}
                            onMerge={handleMerge}
                            onUserSelect={handleOnSelectUserForMerge}
                        />
                    </Tab>
                </Tabs>
            </Box>
        </Layer>
    );
};

export default UserTile;
