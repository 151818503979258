import React from "react";
import { Box, Card, Text } from "grommet";

interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

interface UserCardProps {
    user: User;
    selectedUser: User | null;
    onUserClick: (user: User) => void;
}

const UserCard: React.FC<UserCardProps> = ({ user, selectedUser,  onUserClick }) => {
    return (
        <Card
            key={user.id}
            pad="small"
            background={selectedUser?.id === user.id ? "green-1":  "light-1"}
            round="small"
            margin={{ vertical: "xsmall" }}
            style={{flexShrink: 0}}
            onClick={() => onUserClick(user)}>
            <Box direction="row" gap="small" align="center" justify="between">
                <Box>
                    <Text weight="bold">{user.name}</Text>
                    <Text>{user.birthday}</Text>
                    <Text>{user.gender}</Text>
                </Box>
            </Box>
        </Card>
    );
};

export default UserCard;
