import {
    CheckinEvent,
    ClassroomCheckIn,
    ClothingCheckIn,
    FoodCheckIn,
    VolunteerCheckIn
} from "../../types/CheckInEvent";
import {KitchenEvent, ShowerEvent} from "../../types/CalendarEvent";
import {EventType} from "../../types/EventType";
import {User} from "../../types/User";
import * as ss from "simple-statistics";

export type CheckInEventUnion = CheckinEvent | FoodCheckIn | VolunteerCheckIn | ClassroomCheckIn | ClothingCheckIn | ShowerEvent | KitchenEvent;

//either is or is a child of CheckinEvent
type standardEvent = CheckinEvent | FoodCheckIn | VolunteerCheckIn | ClassroomCheckIn | ClothingCheckIn;
//anything not a standard event
type specialEvent = KitchenEvent | ShowerEvent;

type Listener = () => void;

export interface FiveNumberSummary {
    lowerFence: number;
    firstQuartileValue: number;
    medianValue: number;
    thirdQuartileValue: number;
    upperFence: number;
    outliers: number[];
}


//Singleton
//Handles all the data processing for the checkin events.
class CheckinEventHandler {

    private specialEvents: { [key: string]: specialEvent[] } = {};

    private startDate: Date = new Date();
    private endDate: Date = new Date();

    //key is event id, value is the users for the event category
    private userDictionary: { [key: string]: User[] } = {};

    //key is the event type, value is an array of events of that type
    private eventTypeDictionary: { [key: string]: standardEvent[] } = {};

    //a set for a'' the active service event types
    private activeServiceEventTypes: EventType[] = [];
    private activeSpecialEventTypes: EventType[] = [];

    //listeners for when the data changes
    private listeners: Listener[] = [];

    private static instance: CheckinEventHandler;
    public static getInstance(): CheckinEventHandler {
        if (!CheckinEventHandler.instance) {
            CheckinEventHandler.instance = new CheckinEventHandler();
        }
        return CheckinEventHandler.instance;
    }

    public handleData(serviceEventType: EventType, data: CheckInEventUnion[]): void {

        if (serviceEventType === EventType.KITCHEN || serviceEventType === EventType.SHOWER) {

            this.processSpecialEvent(serviceEventType, data as specialEvent[]);
            return;
        }

        this.processCheckinEvent(serviceEventType, data as standardEvent[]);
    }

    public setServiceEventType(serviceEventType: EventType[]): void {

        this.activeSpecialEventTypes = [];
        this.activeServiceEventTypes = [];

        serviceEventType.forEach((eventType: EventType) => {

            if (eventType === EventType.KITCHEN || eventType === EventType.SHOWER) {
                this.activeSpecialEventTypes.push(eventType);
            }else{
                this.activeServiceEventTypes.push(eventType);
            }

        });

        this.notifyListeners();
    }

    public setDateRange(startDate: Date, endDate: Date): void {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    public setStartDate(startDate: Date): void {
        this.startDate = startDate;
    }

    public setEndDate(endDate: Date): void {
        this.endDate = endDate;
    }

    public subscribe(listener: Listener): () => void {
        this.listeners.push(listener);
        // Return an unsubscribe function
        return () => {
            this.listeners = this.listeners.filter(l => l !== listener);
        };
    }

    private notifyListeners(): void {
        this.listeners.forEach(listener => listener());
    }

    public getDayData(): { x: string; y: number }[] {
        let combinedDayCounts: number[] = [0, 0, 0, 0, 0, 0, 0];
        let seenGuestIDs = new Set<String>();

        //iterate through all the event types
        this.activeServiceEventTypes.forEach((eventType: string) => {
            //iterate through all the events of that type
            if (!(eventType in this.userDictionary)) {
                return;
            }
            //iterate through all the events of that type
            this.eventTypeDictionary[eventType].forEach((event: standardEvent) => {
                const day = new Date(event.date).getDay();
                const dayString = new Date(event.date).toDateString();

                if (!seenGuestIDs.has(event.guest.GuestID + dayString)) {
                    seenGuestIDs.add(event.guest.GuestID + dayString);

                    //get the day of the week

                    //increment the day count
                    combinedDayCounts[day]++;
                }
            });


        });

        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, i) => ({
            x: day,
            y: combinedDayCounts[i],
        }));
    }

    public getGenderData(): number[] {
        const genderData: {
            male: number;
            female: number;
        } = { male: 0, female: 0 };

        let seenGuestIDs = new Set<String>();

        // Iterate through all the event types
        this.activeServiceEventTypes.forEach((eventType: string) => {
            // Check if the eventType exists in the userDictionary
            if (!(eventType in this.userDictionary)) {
                return;
            }

            // Iterate through all the events of that type
            this.userDictionary[eventType].forEach((user: User) => {

                // Check if this guest has already been counted
                if (!seenGuestIDs.has(user.GuestID)) {
                    seenGuestIDs.add(user.GuestID);

                    // Count the guest based on their gender
                    if (user.Gender === "Male") {
                        genderData.male++;
                    } else if (user.Gender === "Female") {
                        genderData.female++;
                    }
                }
            });
        });

        return [genderData.male, genderData.female];
    }

    private calculateAge(birthday: Date): number {
        const today = new Date();
        let age = today.getFullYear() - birthday.getFullYear();
        const month = today.getMonth() - birthday.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
            age--;
        }
        return age;
    }

    public getAgeData(): number[] {

        let ages: number[] = []
        let seenGuestIDs = new Set<String>();

        //iterate through all the event types
        this.activeServiceEventTypes.forEach((eventType: string) => {
            //iterate through all the events of that type
            if (!(eventType in this.userDictionary)) {
                return;
            }

            this.userDictionary[eventType].forEach((user: User) => {
                if (!seenGuestIDs.has(user.GuestID)) {
                    seenGuestIDs.add(user.GuestID);
                    ages.push(this.calculateAge(user.Birthday));
                }
            });
        });

        return ages;
    }

    public getEvents(serviceEventType?: EventType): standardEvent[] {

        if (serviceEventType !== undefined) {
            return this.eventTypeDictionary[serviceEventType];
        }

        let events: standardEvent[] = [];

        //iterate through all the event types
        this.activeServiceEventTypes.forEach((eventType: string) => {
            //iterate through all the events of that type
            if (!(eventType in this.userDictionary)) {
                return;
            }
            //iterate through all the events of that type
            this.eventTypeDictionary[eventType].forEach((event: standardEvent) => {
                events.push(event);
            });
        });


        return events;
    }

    public getSpecialEvents(serviceEventType?: EventType): specialEvent[] {

        if (serviceEventType !== undefined) {
            return this.specialEvents[serviceEventType];
        }


        let events: specialEvent[] = [];

        this.activeSpecialEventTypes.forEach((eventType: string) => {
            //iterate through all the events of that type
            if (!(eventType in this.specialEvents)) {
                return;
            }
            //iterate through all the events of that type
            this.specialEvents[eventType].forEach((event: specialEvent) => {
                events.push(event);
            });
        });


        return events;
    }

    public static computeFiveNumberSummary(data: number[]): FiveNumberSummary {
        data.sort((a, b) => a - b);

        const min = data[0];
        const max = data[data.length - 1];

        const firstQuartileValue = ss.quantile(data, 0.25);
        const medianValue = ss.quantile(data, 0.5);
        const thirdQuartileValue = ss.quantile(data, 0.75);

        const interQuartileRange = thirdQuartileValue - firstQuartileValue; // Interquartile range
        const lowerFenceLimit = firstQuartileValue - 1.5 * interQuartileRange;
        const upperFenceLimit = thirdQuartileValue + 1.5 * interQuartileRange;

        const potentialOutliers = data.filter(value => value < lowerFenceLimit || value > upperFenceLimit);

        const lowerFence = min;
        const upperFence = max;

        return {
            lowerFence,
            firstQuartileValue,
            medianValue,
            thirdQuartileValue,
            upperFence,
            outliers: potentialOutliers
        };
    }

    private processCheckinEvent(serviceEventType: EventType, data: standardEvent[]): void {

        //clear old data for the service event type specified
        delete this.userDictionary[serviceEventType];
        delete this.eventTypeDictionary[serviceEventType];

        data.forEach((event: standardEvent) => {

            //get all unique users
            if (!(serviceEventType in this.userDictionary)) {
                this.userDictionary[serviceEventType] = [event.guest];
            }else{
                this.userDictionary[serviceEventType].push(event.guest);
            }

            //add to its event type list
            if (!(serviceEventType in this.eventTypeDictionary)) {
                this.eventTypeDictionary[serviceEventType] = [event];
            }else{
                this.eventTypeDictionary[serviceEventType].push(event);
            }

        });

    }

    private processSpecialEvent(serviceEventType: EventType, data: specialEvent[]): void {

        //clear old data for the service event type specified
        delete this.specialEvents[serviceEventType];

        this.specialEvents[serviceEventType] = data;

    }



}

export default CheckinEventHandler;