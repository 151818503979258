import React from 'react';
import { Box, Button, Layer, Text } from 'grommet';

interface ConfirmationLayerProps {
    editedUser: {
        name: string;
        birthday: string;
        gender: string;
    };
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationLayer: React.FC<ConfirmationLayerProps> = ({ editedUser, onConfirm, onCancel }) => (
    <Layer onEsc={onCancel} onClickOutside={onCancel}>
        <Box pad="medium" gap="small" width="medium">
            <Text>Confirm the new information:</Text>
            <Text>Name: {editedUser.name}</Text>
            <Text>Birthday: {editedUser.birthday}</Text>
            <Text>Sex: {editedUser.gender}</Text>
            <Box direction="row" gap="medium" justify="between">
                <Button label="Confirm" onClick={onConfirm} />
                <Button label="Cancel" onClick={onCancel} />
            </Box>
        </Box>
    </Layer>
);

export default ConfirmationLayer;
