// src/App.tsx
import React, {useState, useEffect} from "react";
import {Grommet, Box, Tab, Tabs, Header, Button, Layer, Text} from "grommet";
import {QueryClient, QueryClientProvider} from 'react-query'


import theme from "./theme";
import Clothing from "./Components/Clothing/Clothing";
import Mailroom from "./Components/Mailroom/Mailroom";
import Shower from "./Components/Shower/Shower";
import Laundry from "./Components/Laundry/Laundry";
import Kitchen from "./Components/Kitchen/Kitchen";
import Classroom from "./Components/Classroom/Classroom";
import Food from "./Components/Food/Food";
import {EventType, ExcludeEventTypes} from "./types/EventType";
import LoginHandler from "./Components/Login/LoginHandler";
import {Auth} from 'aws-amplify';
import Volunteer from "./Components/Volunteer/Volunteer";
import Report from "./Components/Report/Report";
import {ReactQueryDevtools} from 'react-query/devtools'
import UserEdit from "./Components/UserEdit/UserEdit";


const awsConfig = require('./aws-exports').default;
Auth.configure(awsConfig);

const queryClient = new QueryClient();


const App: React.FC = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showLogin, setShowLogin] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        // This effect runs when 'showLogin' changes.
        checkAuthentication();
    }, [showLogin]);

    const checkAuthentication = async () => {
        try {
            //check what group the user is in
            const user = await Auth.currentAuthenticatedUser();
            const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

            if (groups && groups.includes("AdminAccounts")) {
                setAdmin(true);
            } else {
                setAdmin(false);
            }


            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
        }
    };

    const onTabChange = (index: number) => {
        setSelectedIndex(index);
    };

    const toggleLogin = () => {
        setShowLogin(!showLogin);
    };

    const filterEventTypes = (eventType: EventType): boolean => {
        return !ExcludeEventTypes.includes(eventType);
    };

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/>
            {<Grommet theme={theme} full>
                <Box fill>
                    <Header background="brand">
                        <Box direction="row" align="center" justify="between" fill="horizontal" pad={'medium'}>
                            <Box/>
                            <Box align="center">
                                <Tabs activeIndex={selectedIndex} onActive={onTabChange}>
                                    {Object.values(EventType).filter(filterEventTypes).map((eventType) => (
                                        <Tab key={eventType} title={eventType}></Tab>
                                    ))}
                                    {admin && <Tab key={"UserEdit"} title={"User Edit"}></Tab>}
                                </Tabs>
                            </Box>
                            <Box align="end">
                                <Button primary label={isAuthenticated ? "Logout" : "Login"} onClick={toggleLogin}/>
                            </Box>
                        </Box>
                    </Header>
                    {!isAuthenticated && (
                        <Header background="#fc532d">
                            <Box direction="row" align="center" justify="between" fill="horizontal" pad={'small'}>
                                <Text size="medium">Not Logged In</Text>
                            </Box>
                        </Header>
                    )}
                    <Box fill>
                        {(() => {
                            const components = [
                                <Clothing/>,
                                <Laundry/>,
                                <Kitchen/>,
                                <Shower/>,
                                <Mailroom/>,
                                <Classroom/>,
                                <Food/>,
                                <Volunteer/>,
                                <Report/>,
                                <UserEdit/>,
                            ];

                            return components[selectedIndex];
                        })()}
                    </Box>
                    {showLogin && (
                        <Layer position="center" onClickOutside={toggleLogin} onEsc={toggleLogin}>
                            <LoginHandler onClose={() => setShowLogin(false)}/>
                        </Layer>
                    )}
                </Box>
            </Grommet>}
        </QueryClientProvider>
    );
};

export default App;
