import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Select, Text, TextInput} from 'grommet';
import {Sync} from 'grommet-icons';
import DateInput from '../../common/DateInput';
import ConfirmationLayer from './ConfirmationLayer';
import {patchGuest} from '../../Api/userEditApi';
import {UserUpdateOperations} from "../../types/UserUpdateOperations";

interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

interface UserInfoTabProps {
    user: User;
    editedUser: User;
    onUserChange: (user: User) => void;
    onClose: () => void;
}

const UserInfoTab: React.FC<UserInfoTabProps> = ({ user, editedUser, onUserChange, onClose }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isSyncDisabled, setIsSyncDisabled] = useState(true);

    useEffect(() => {
        const isUserChanged =
            user.name !== editedUser.name ||
            user.birthday !== editedUser.birthday ||
            user.gender !== editedUser.gender;
        setIsSyncDisabled(!isUserChanged);
    }, [user, editedUser]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onUserChange({
            ...editedUser,
            [event.target.name]: event.target.value,
        });
    };

    const handleDateChange = (date: string) => {
        const [mm, dd, yyyy] = date.split('-');
        onUserChange({
            ...editedUser,
            birthday: `${mm}/${dd}/${yyyy}`,
        });
        editedUser.birthday = `${mm}/${dd}/${yyyy}`;
    };

    const handleGenderChange = ({ option }: { option: string }) => {
        onUserChange({
            ...editedUser,
            gender: option,
        });
    };

    const handleSync = () => {
        setShowConfirmation(true);
    };

    const handleConfirm = async () => {
        setShowConfirmation(false);
        await patchGuest({
            operation: UserUpdateOperations.UPDATE,
            userToKeep: editedUser.id,
            updateJson: JSON.stringify({
                name: editedUser.name,
                birthday: editedUser.birthday,
                gender: editedUser.gender,
            }),
            userToDelete: "",
        });
        onClose();
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <Grid
                rows={['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']}
                columns={['1/2', '1/2']}
                gap="small"
                margin={{ top: 'medium' }}
                areas={[
                    { name: 'nameLabel', start: [0, 0], end: [1, 0] },
                    { name: 'nameCurrent', start: [0, 1], end: [0, 1] },
                    { name: 'nameEdit', start: [1, 1], end: [1, 1] },
                    { name: 'birthdayLabel', start: [0, 2], end: [1, 2] },
                    { name: 'birthdayCurrent', start: [0, 3], end: [0, 3] },
                    { name: 'birthdayEdit', start: [1, 3], end: [1, 3] },
                    { name: 'genderLabel', start: [0, 4], end: [1, 4] },
                    { name: 'genderCurrent', start: [0, 5], end: [0, 5] },
                    { name: 'genderEdit', start: [1, 5], end: [1, 5] },
                    { name: 'syncButton', start: [0, 6], end: [1, 6] },
                ]}
                fill
            >
                <Box gridArea="nameLabel">
                    <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Name
                    </Text>
                </Box>

                <Box gridArea="nameCurrent" direction="row" align="center" gap="small" wrap={false}>
                    <Text size="medium" textAlign="start" style={{ whiteSpace: 'nowrap' }}>
                        {user.name}
                    </Text>
                </Box>
                <Box gridArea="nameEdit">
                    <TextInput name="name" value={editedUser.name} onChange={handleInputChange} />
                </Box>

                <Box gridArea="birthdayLabel">
                    <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Birthday
                    </Text>
                </Box>
                <Box gridArea="birthdayCurrent" direction="row" align="center" gap="small" wrap={false}>
                    <Text size="medium" textAlign="start" style={{ whiteSpace: 'nowrap' }}>
                        {user.birthday}
                    </Text>
                </Box>
                <Box gridArea="birthdayEdit">
                    <DateInput initialDate={user.birthday} onDateChange={handleDateChange} />
                </Box>

                <Box gridArea="genderLabel">
                    <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Sex
                    </Text>
                </Box>
                <Box gridArea="genderCurrent" direction="row" align="center" gap="small" wrap={false}>
                    <Text size="medium" textAlign="start" style={{ whiteSpace: 'nowrap' }}>
                        {user.gender}
                    </Text>
                </Box>
                <Box gridArea="genderEdit">
                    <Select
                        name="gender"
                        options={['Male', 'Female']}
                        value={editedUser.gender}
                        onChange={handleGenderChange}
                    />
                </Box>

                <Box gridArea="syncButton" direction="row" gap="small" align="center">
                    <Button icon={<Sync />} primary label="Sync" onClick={handleSync} disabled={isSyncDisabled} />
                </Box>
            </Grid>
            {showConfirmation && (
                <ConfirmationLayer
                    editedUser={editedUser}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
};

export default UserInfoTab;
