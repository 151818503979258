import React, {useEffect, useState} from "react";
import {Box, FormField, DateInput, CheckBoxGroup, Heading, Button} from "grommet";
import {EventType, ExcludeEventTypes} from "../../types/EventType";
import moment from "moment/moment";
import {useQueryClient} from "react-query";
import {Close} from "grommet-icons";
import {dataFormat} from "../../types/Constants";
import CheckinEventHandler from "./CheckinEventHandler";

interface ReportSelectionProps {
    setSelectedDates: React.Dispatch<React.SetStateAction<{
        start: Date;
        end: Date;
    }>>;
    setSelectedService: React.Dispatch<React.SetStateAction<Array<EventType>>>;
    showSidebar: boolean;
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    downloadCsv: () => void;
}

const ReportSelection: React.FC<ReportSelectionProps> = ({
    setSelectedDates,
    setSelectedService,
    showSidebar,
    setShowSidebar,
    downloadCsv
}) => {
    const queryClient = useQueryClient();
    const checkInEventHandlers = CheckinEventHandler.getInstance();

    const initialEndDate = setTimeToEndOfDay(new Date());
    const initialStartDate = setTimeToStartOfDay(new Date(initialEndDate.getFullYear(), initialEndDate.getMonth() - 1, initialEndDate.getDate()));

    checkInEventHandlers.setDateRange(initialStartDate, initialEndDate);

    const [selectedServices, setSelectedServices] = useState<Array<EventType>>([EventType.CLOTHING]);
    const [selectedDateStrings, setSelectedDatesStrings] = useState({
        start: "",
        end: ""
    });

    function setTimeToStartOfDay(date: Date): Date {
        return new Date(date.setHours(0, 0, 0, 0));
    }

    function setTimeToEndOfDay(date: Date): Date {
        return new Date(date.setHours(23, 59, 59, 999));
    }

    useEffect(() => {
        setSelectedService(selectedServices);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedServices]);

    useEffect(() => {
        setSelectedDatesStrings({
            start: initialStartDate.toISOString().substring(0, 10),
            end: initialEndDate.toISOString().substring(0, 10)
        });

        setSelectedDates({
            start: initialStartDate,
            end: initialEndDate
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleDateChange = (name: string, value: string) => {
        setSelectedDatesStrings(prev => ({ ...prev, [name]: value }));
        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            const dateParts = value.split("-").map(part => parseInt(part, 10));

            // Note: JavaScript counts months from 0 to 11, so we need to subtract 1 from the month part
            let localDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

            if(name === 'start'){
                localDate = setTimeToStartOfDay(localDate);
                checkInEventHandlers.setStartDate(localDate);
            }
            else if(name === 'end'){
                localDate = setTimeToEndOfDay(localDate);
                checkInEventHandlers.setEndDate(localDate);
            }

            setSelectedDates(prev => ({ ...prev, [name]: localDate }));

            queryClient.invalidateQueries({
                // @ts-ignore
                predicate: (query ) => typeof query.queryKey[0] === "string" && query.queryKey[0].endsWith("-Report")
            });
        }
    };

    const excludeEventTypes = [...ExcludeEventTypes, EventType.REPORT];

    if (!showSidebar)
        return (<></>);
    else
        return (
            <Box
                direction={"column"}
                width={{min: "200px", max:"275px", width:"20vw"}}
                background={"brandSecondary"}
                flex
            >
                <Box direction="column"  justify="start" pad={{horizontal: "medium"}}>
                    <Box width={"20vw"}/>

                    <Box direction={"column"} justify={"start"} margin={{top:'small'}} style={{flexShrink: 0}}>
                        <Box direction={"row"} justify={"between"} align={"center"} margin={{bottom:"medium"}} >
                            <Heading margin={"none"} level={2}>Menu</Heading>
                            <Button icon={<Close height={"100%"}/>} onClick={() => setShowSidebar(false)}/>
                        </Box>

                        <Heading margin={{bottom:"small", top:"none"}} level={3}>Service</Heading>
                        <CheckBoxGroup
                            options={Object.values(EventType).filter(
                                eventType => !excludeEventTypes.includes(eventType))}
                            value={selectedServices}
                            onChange={(event: any) => setSelectedServices(event.value)}
                        />
                    </Box>

                    <Box direction={"column"} justify={"start"} style={{flexShrink: 0}} margin={{top:'small'}}>
                        <Heading margin={"none"} level={3}>Date Range</Heading>
                        <Box fill={"vertical"} justify={"center"} style={{flexShrink: 0}}>
                            <FormField
                                label="From"
                            >
                                <DateInput
                                    name={"start"}
                                    format={dataFormat}
                                    value={selectedDateStrings.start}
                                    onChange={(event) => handleDateChange('start', event.value as string)}
                                />
                            </FormField>
                            <FormField
                                label="To"
                            >
                                <DateInput
                                    format={dataFormat}
                                    value={selectedDateStrings.end}
                                    onChange={(event) => handleDateChange('end', event.value as string)}
                                />
                            </FormField>
                        </Box>

                    </Box>

                    <Box direction={"column"} justify={"start"} style={{flexShrink: 0}} margin={{top:'medium'}}>
                        <Heading margin={{bottom:"medium", top:"none"}} level={3}>Download</Heading>
                        <Button label={"Download CSV"} onClick={downloadCsv}/>
                    </Box>

                </Box>
            </Box>
        );
};

export default ReportSelection;