// src/theme.ts
import { ThemeType } from "grommet";

const theme: ThemeType = {
    global: {
        colors: {
            brand: "#607d8b",
            brandSecondary: "#B7C5CD",
            focus: "#ffb74d",
            "accent-1": "#ffb74d",
            "accent-2": "#4dd0e1",
            "accent-3": "#9575cd",
            "accent-4": "#81c784",
            "neutral-1": "#795548",
            "neutral-2": "#607d8b",
            "neutral-3": "#9e9e9e",
            "neutral-4": "#37474f",
            "green-1": "rgba(176,253,181,0.9)", //lighter
            "green-2": "rgba(76,175,80,0.5)", //darker
            "red-3": "#BB3E1F", //lighter
            "red-2": "rgba(255,52,52,0.5)", //darker
            "red-1": "rgba(255,0,0,0.2)", //lighter
            "blue-1": "#CAF0F8", //lighter
            "blue-2": "#ADE8F4", //darker
            "status-critical": "#ef5350",
            "status-warning": "#fb8c00",
            "status-ok": "#69b66d",
            "status-unknown": "#a1887f",
            "status-disabled": "#e0e0e0",
            background: "#ffffff",
            "text-strong": "#000000",
            "text-weak": "#757575",
            "text-xweak": "#bdbdbd",
            "text-xxweak": "#e0e0e0",
            "selected-background": "brand",
            "selected-text": "text-strong",
        },
        font: {
            family: "Roboto, sans-serif",
            size: "18px",
            height: "24px",
        },
    },
};

export default theme;
