import React, { useState } from 'react';
import { Grid, Box, Text, Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import MergeConfirmationLayer from './MergeConfirmationLayer';
import {patchGuest} from "../../Api/userEditApi";
import {UserUpdateOperations} from "../../types/UserUpdateOperations";

interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

interface UserMergeTabProps {
    userToMerge: User;
    userToDelete: User | null;
    onMerge: (firstUser: User, secondUser: User) => void;
    onUserSelect: () => void;
}

const UserMergeTab: React.FC<UserMergeTabProps> = ({ userToMerge, userToDelete, onMerge, onUserSelect }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleMerge = () => {
        setShowConfirmation(true);
    };

    const handleConfirm = async () => {
        if (userToDelete) {
            await patchGuest({
                operation: UserUpdateOperations.MERGE,
                userToKeep: userToMerge.id,
                userToDelete: userToDelete.id,
                updateJson: "",
            });
            onMerge(userToMerge, userToDelete);
        }
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const handleSelectUser = () => {
        onUserSelect();
    }

    return (
        <>
            <Grid
                rows={['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']}
                columns={['fill', 'fill', 'fill']}
                gap={{ row: 'small', column: 'large' }}
                margin={{ top: 'medium' }}
                areas={[
                    { name: 'mergeUserLabel', start: [0, 0], end: [0, 0] },
                    { name: 'mergeUserNameLabel', start: [0, 1], end: [0, 1] },
                    { name: 'mergeUserName', start: [0, 2], end: [0, 2] },
                    { name: 'mergeUserBirthdayLabel', start: [0, 3], end: [0, 3] },
                    { name: 'mergeUserBirthday', start: [0, 4], end: [0, 4] },
                    { name: 'mergeUserGenderLabel', start: [0, 5], end: [0, 5] },
                    { name: 'mergeUserGender', start: [0, 6], end: [0, 6] },
                    { name: 'deleteUserLabel', start: [2, 0], end: [2, 0] },
                    { name: 'deleteUserNameLabel', start: [2, 1], end: [2, 1] },
                    { name: 'deleteUserName', start: [2, 2], end: [2, 2] },
                    { name: 'deleteUserBirthdayLabel', start: [2, 3], end: [2, 3] },
                    { name: 'deleteUserBirthday', start: [2, 4], end: [2, 4] },
                    { name: 'deleteUserGenderLabel', start: [2, 5], end: [2, 5] },
                    { name: 'deleteUserGender', start: [2, 6], end: [2, 6] },
                    { name: 'mergeButton', start: [0, 7], end: [0, 7] },
                    { name: 'SelectUser', start: [2, 7], end: [2, 7] },
                    { name: 'centerColumn', start: [1, 2], end: [1, 4]}
                ]}
            >
                <Box gridArea="mergeUserLabel" align="center" margin={{ bottom: 'small' }}>
                    <Text size="xlarge" style={{ fontWeight: 'bold' }}>
                        User to Merge
                    </Text>
                </Box>

                <Box gridArea="mergeUserNameLabel">
                    <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Name
                    </Text>
                </Box>
                <Box gridArea="mergeUserName" justify="center">
                    <Text>Name: {userToMerge.name}</Text>
                </Box>

                <Box gridArea="mergeUserBirthdayLabel">
                    <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Birthday
                    </Text>
                </Box>
                <Box gridArea="mergeUserBirthday" justify="center">
                    <Text>Birthday: {userToMerge.birthday}</Text>
                </Box>

                <Box gridArea="mergeUserGenderLabel">
                    <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        Gender
                    </Text>
                </Box>
                <Box gridArea="mergeUserGender" justify="center">
                    <Text>Gender: {userToMerge.gender}</Text>
                </Box>

                <Box gridArea="deleteUserLabel" align="center">
                    <Text size="xlarge" style={{ fontWeight: 'bold' }}>
                        User to Delete
                    </Text>
                </Box>

                <Box gridArea="centerColumn" align="center" justify='center' fill>
                    <LinkPrevious size={"large"}/>
                </Box>

                {userToDelete ? (
                    <>
                        <Box gridArea="deleteUserNameLabel">
                            <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                Name
                            </Text>
                        </Box>
                        <Box gridArea="deleteUserName" justify="center">
                            <Text>Name: {userToDelete.name}</Text>
                        </Box>

                        <Box gridArea="deleteUserBirthdayLabel">
                            <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                Birthday
                            </Text>
                        </Box>
                        <Box gridArea="deleteUserBirthday" justify="center">
                            <Text>Birthday: {userToDelete.birthday}</Text>
                        </Box>

                        <Box gridArea="deleteUserGenderLabel">
                            <Text size="large" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                Gender
                            </Text>
                        </Box>
                        <Box gridArea="deleteUserGender" justify="center">
                            <Text>Gender: {userToDelete.gender}</Text>
                        </Box>
                        <Box gridArea={"SelectUser"} justify="center" margin={{ top: 'medium' }}>
                            <Button primary label="Select Different User" onClick={handleSelectUser}/>
                        </Box>
                    </>
                ) : (
                    <Box gridArea="deleteUserBirthdayLabel" justify="center" >
                        <Button primary label="Select User" onClick={handleSelectUser}/>
                    </Box>
                )}

                <Box gridArea="mergeButton" direction="row" align="center" margin={{ top: 'medium' }}>
                    <Button primary label="Merge" onClick={handleMerge} disabled={!userToDelete} />
                </Box>
            </Grid>

            {showConfirmation && userToDelete && (
                <MergeConfirmationLayer
                    correctUser={userToMerge}
                    incorrectUser={userToDelete}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
};

export default UserMergeTab;
